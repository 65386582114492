import { Logger, ILogObj } from 'tslog';

/**
 * Creates a logger instance with a specified prefix.
 * @param prefix - The prefix to be used for log messages.
 * @returns A Logger instance.
 */
export function createLogger(prefix: string): Logger<ILogObj> {
  return new Logger<ILogObj>({
    type: 'pretty',
    prettyLogTemplate:
      '{{yyyy}}-{{mm}}-{{dd}} {{hh}}:{{MM}}:{{ss}}:{{ms}} {{logLevelName}} ',
    prefix: [prefix],
  });
}

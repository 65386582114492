import { AppointmentReminder, Address } from '@revival/scheduler';
import { create } from 'zustand';
import { addDays, subHours } from 'date-fns';
import {
  addNewPatientAddress,
  getAvailableAppointmentSlots,
  getCalendarConfig,
} from '../services/revival';

type State = {
  reminder: AppointmentReminder | null;
  selectedAddress: Address | null;
  availableAppointmentSlots: any;
  selectedDate: Date | null;
  calendarConfig: any;
  displayConfirmation: boolean;
};

type Actions = {
  setReminder: (reminder: AppointmentReminder) => void;
  setSelectedAddress: (address: Address | null) => void;
  setAvailableAppointmentSlots: (slots: any) => void;
  setSelectedDate: (date: Date) => void;
  setCalendarConfig: (config: any) => void;
  addNewPatientAddress: (
    patientId: string,
    address: Partial<Address>,
    token: string | null,
  ) => Promise<any>; //TODO: define return type
  getCalendarConfig: (facilityId: string, token: string | null) => Promise<any>; //TODO: define return type
  getAvailableAppointmentSlots: (
    date: string,
    facilityId: string,
    token: string | null,
  ) => Promise<any>; //TODO: define return type
  setDisplayConfirmation: (display: boolean) => void;
};

type SchedulerStore = State & Actions;

export const useSchedulerStore = create<SchedulerStore>((set, get) => ({
  reminder: null,
  selectedAddress: null,
  availableAppointmentSlots: {},
  calendarConfig: null,
  selectedDate: addDays(subHours(new Date(), 7), 1), // tomorrow
  setReminder: (reminder) => set({ reminder }),
  setSelectedAddress: (address) => set({ selectedAddress: address }),
  setAvailableAppointmentSlots: (slots) =>
    set({ availableAppointmentSlots: slots }),
  setSelectedDate: (date) => set({ selectedDate: date }),
  addNewPatientAddress: async (patientId, address, token) => {
    const { success, data } = await addNewPatientAddress(
      patientId,
      address,
      token,
    );
    set({ selectedAddress: data });
    return { success, data };
  },
  getAvailableAppointmentSlots: async (date, facilityId, token) => {
    const _selectedAddress = get().selectedAddress;

    if (!_selectedAddress) {
      return;
    }

    const { success, data } = await getAvailableAppointmentSlots(
      date,
      _selectedAddress.id,
      facilityId,
      token,
    );
    set({ availableAppointmentSlots: data.slots });
    return { success, data };
  },
  getCalendarConfig: async (facilityId, token) => {
    const { success, data } = await getCalendarConfig(facilityId, token);
    set({ calendarConfig: data });
    return { success, data };
  },
  setCalendarConfig: (config) => set({ calendarConfig: config }),
  displayConfirmation: false,
  setDisplayConfirmation: (display) => set({ displayConfirmation: display }),
}));

import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { FaMapLocationDot, FaBuilding } from 'react-icons/fa6';
import { Address } from '@revival/scheduler';

interface AddressCardProps {
  address: Address;
  onSelect: (address: Address) => void;
  type?: 'patient' | 'facility';
}

export const AddressCard = ({
  address,
  onSelect,
  type = 'patient',
}: AddressCardProps) => {
  const Icon = type === 'facility' ? FaBuilding : FaMapLocationDot;

  return (
    <Box w={{ base: '90%', lg: '75%' }}>
      <Box
        w={'100%'}
        pl={2}
        py={2}
        border={'1px solid'}
        borderColor={'var(--chakra-colors-chakra-border-color)'}
        borderRadius={5}
        onClick={() => onSelect(address)}
        cursor={'pointer'}
      >
        <HStack>
          <Box mx={3}>
            <Icon size={30} color={'var(--chakra-colors-messenger-500)'} />
          </Box>
          <VStack
            w={'100%'}
            gap={0}
            alignItems={'left'}
            flex={1}
            userSelect={'none'}
          >
            <Text>{address.label}</Text>
            <Text>{address.street}</Text>
            <Text>{`${address.city}, ${address.state} ${address.zip}`}</Text>
          </VStack>
        </HStack>
      </Box>
    </Box>
  );
};

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { BsHouseAddFill } from 'react-icons/bs';
import React, { useState } from 'react';
import { useSchedulerStore } from '../../stores/SchedulerStore';
import { Address } from '@revival/scheduler';
import { StateSelect } from './StateSelect';
import useToken from '../../hooks/useToken';

export const AddNewAddressButton = () => {
  const { token } = useToken();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const addNewPatientAddress = useSchedulerStore(
    (state) => state.addNewPatientAddress,
  );
  const reminder = useSchedulerStore((state) => state.reminder);
  const [isSaving, setIsSaving] = useState(false);
  const [address, setAddress] = useState<Partial<Address>>({
    label: '',
    street: '',
    city: '',
    state: 'AZ',
    zip: '',
  });
  const [formErrors, setFormErrors] = useState<Partial<Address>>({});

  const saveNewAddress = async () => {
    if (reminder) {
      setIsSaving(true);

      // validate address
      const _formErrors: Partial<Address> = {};
      if (!address.label) _formErrors.label = 'Label is required';
      if (!address.street) _formErrors.street = 'Street is required';
      if (!address.city) _formErrors.city = 'City is required';
      if (!address.state) _formErrors.state = 'State is required';
      if (!address.zip) _formErrors.zip = 'Zip code is required';

      if (Object.keys(_formErrors).length > 0) {
        setFormErrors(_formErrors);
        setIsSaving(false);
        return;
      }

      // save address
      const { success } = await addNewPatientAddress(
        reminder.patient.id,
        address,
        token,
      );

      if (success) {
        onClose();
      } else {
        setIsSaving(false);
        //TODO: display error message
      }
    }
    setIsSaving(false);
  };

  const onChange = (e: any) => {
    const field = e.target.id;
    setAddress({ ...address, [field]: e.target.value });
  };

  return (
    <>
      <Box
        w={{ base: '90%', lg: '75%' }}
        pl={2}
        py={2}
        border={'1px solid'}
        borderColor={'var(--chakra-colors-chakra-border-color)'}
        borderRadius={5}
        onClick={onOpen}
        cursor={'pointer'}
        color={'messenger.300'}
        _hover={{ color: 'messenger.500' }}
      >
        <Flex ml={'25%'} alignItems={'center'}>
          <Box mx={3}>
            <BsHouseAddFill size={30} />
          </Box>
          <Text userSelect={'none'}>Add New</Text>
        </Flex>
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        size={{ base: 'full', lg: 'md' }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Address</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <FormControl isRequired isInvalid={!!formErrors.label}>
                <FormLabel>Label</FormLabel>
                <Input
                  id={'label'}
                  type="text"
                  placeholder={'Home, Office, etc.'}
                  value={address.label}
                  onChange={onChange}
                />
                <FormErrorMessage>{formErrors.label}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!formErrors.street}>
                <FormLabel>Street</FormLabel>
                <Input
                  id={'street'}
                  type="text"
                  value={address.street}
                  onChange={onChange}
                />
                <FormErrorMessage>{formErrors.street}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!formErrors.city}>
                <FormLabel>City</FormLabel>
                <Input
                  id={'city'}
                  type="text"
                  value={address.city}
                  onChange={onChange}
                />
                <FormErrorMessage>{formErrors.city}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!formErrors.state}>
                <FormLabel>State</FormLabel>
                <StateSelect
                  id={'state'}
                  value={address.state}
                  onChange={onChange}
                />
                <FormErrorMessage>{formErrors.state}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired isInvalid={!!formErrors.zip}>
                <FormLabel>Zip Code</FormLabel>
                <Input
                  id={'zip'}
                  type="number"
                  pattern="\d*"
                  value={address.zip}
                  onChange={onChange}
                />
                <FormErrorMessage>{formErrors.zip}</FormErrorMessage>
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={saveNewAddress}
              isLoading={isSaving}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

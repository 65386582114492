import { Card, CardBody, Text, VStack } from '@chakra-ui/react';
import { AddNewAddressButton } from './AddNewAddressButton';
import { useSchedulerStore } from '../../stores/SchedulerStore';
import { AddressCard } from './AddressCard';
import { Address } from '@revival/scheduler';

export const SelectAddressPanel = () => {
  const reminder = useSchedulerStore((state) => state.reminder);
  const { setSelectedAddress } = useSchedulerStore();

  const officeAddress: Address | undefined =
    reminder?.patient?.organization?.address;

  return (
    <Card
      rounded={0}
      padding={0}
      // minW={'600px'}
      maxW={'500px'}
      m={'0 auto auto auto'}
    >
      <CardBody p={5} w={'100%'}>
        <VStack gap={{ base: '10px', lg: '25px' }} w={'100%'}>
          <Text fontSize={'lg'} fontWeight={'bold'} pl={2}>
            Select Your Appointment Address
          </Text>

          {reminder && reminder.patient && officeAddress && (
            <AddressCard
              key="address-office"
              address={officeAddress}
              onSelect={setSelectedAddress}
              type="facility"
            />
          )}

          {reminder &&
            reminder.patient.addresses?.map((address, index) => (
              <AddressCard
                key={`address-${index}`}
                address={address}
                onSelect={setSelectedAddress}
                type="patient"
              />
            ))}
          <AddNewAddressButton />
        </VStack>
      </CardBody>
    </Card>
  );
};

import {
  Box,
  Button,
  Center,
  Flex,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import Calendar from './Calendar';
import { addMinutes, format } from 'date-fns';
import { FaGlobeAmericas } from 'react-icons/fa';
import { useSchedulerStore } from '../../stores/SchedulerStore';
import { createAppointment } from '../../services/revival';
import useToken from '../../hooks/useToken';
import { createLogger } from '@revival/scheduler';

const log = createLogger('[AppointmentCalendar]');

export const AppointmentCalendar = () => {
  const today = new Date();
  const { token } = useToken();
  const slotsRef = useRef(null);

  const {
    selectedDate,
    setSelectedDate,
    availableAppointmentSlots,
    setAvailableAppointmentSlots,
    calendarConfig,
    selectedAddress,
    setDisplayConfirmation,
  } = useSchedulerStore();

  const handleNewAppointment = async (time: string) => {
    const response = window.confirm(`Book appointment for ${time}?`);
    if (!response) return;
    if (selectedDate) {
      // create appointment
      const [timeString, ampm] = time.split(' ');
      const [hours, mins] = timeString.split(':');

      const hours24 =
        ampm === 'PM' && hours !== '12'
          ? parseInt(hours) + 12
          : ampm === 'AM' && hours === '12'
            ? 0
            : parseInt(hours);

      let startTime = new Date(selectedDate);
      startTime.setUTCHours(hours24);
      startTime.setUTCMinutes(parseInt(mins));

      const appointmentDuration = 60;
      const endTime = addMinutes(startTime, appointmentDuration);

      const appointment = {
        provider_id: availableAppointmentSlots[time],
        start_time: startTime.toISOString(),
        end_time: endTime.toISOString(),
        address_id: selectedAddress?.id,
      };
      log.info('creating appointment:', appointment);

      const result = await createAppointment(appointment, token);
      if (result.success) {
        log.info('appointment created:', result);
        setDisplayConfirmation(true);
      }
    }

    setAvailableAppointmentSlots({
      ...availableAppointmentSlots,
      [time]: !availableAppointmentSlots[time],
    });
  };

  useEffect(() => {
    if (
      slotsRef &&
      slotsRef.current &&
      selectedDate &&
      availableAppointmentSlots &&
      Object.keys(availableAppointmentSlots).length > 0
    ) {
      // @ts-ignore
      slotsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [availableAppointmentSlots, selectedDate]);

  return (
    <Box
      w={{ base: '100%' }}
      p={5}
      borderLeft={'var(--chakra-colors-chakra-border-color) 1px solid'}
    >
      <Box mb={'15px'}>
        <Text fontSize={'lg'} fontWeight={'bold'} pl={2}>
          Select a Date & Time
        </Text>
      </Box>
      <Flex flexDirection={{ base: 'column', lg: 'row' }} gap={'25px'}>
        <Box w={{ base: '100%' }}>
          <Calendar
            setSelectedDate={setSelectedDate}
            minDate={calendarConfig.startDate}
            maxDate={calendarConfig.endDate}
            disabledWeekDays={[0, 6]}
            disabledDatesList={calendarConfig.disabledDatesList}
          />

          <Flex mt={5}>
            <Box p={1}>
              <FaGlobeAmericas />
            </Box>
            <Text fontSize={'sm'}>
              {Intl.DateTimeFormat().resolvedOptions().timeZone} -{' '}
              {format(today, 'p')}
            </Text>
          </Flex>
        </Box>

        <VStack spacing={3} w={{ base: '100%', lg: '50%' }} m={'0 auto'}>
          {selectedDate && availableAppointmentSlots && (
            <>
              <Text
                ref={slotsRef}
                textAlign={'left'}
                w={'100%'}
                h={'32px'}
                pt={1}
              >
                {format(selectedDate, 'eeee, MMMM dd')}
              </Text>

              {Object.keys(availableAppointmentSlots).map((time) => (
                <Button
                  key={time}
                  colorScheme={
                    availableAppointmentSlots[time] ? 'messenger' : 'gray'
                  }
                  onClick={() => handleNewAppointment(time)}
                  isDisabled={!availableAppointmentSlots[time]}
                  w={'100%'}
                  size={'lg'}
                >
                  {time}
                </Button>
              ))}
            </>
          )}

          {selectedDate &&
            availableAppointmentSlots &&
            Object.keys(availableAppointmentSlots).length === 0 && (
              <Text fontStyle="italic">No appointments available</Text>
            )}

          {(!selectedDate || !availableAppointmentSlots) && (
            <Center>
              <Spinner size={'xl'} />
            </Center>
          )}
        </VStack>
      </Flex>
    </Box>
  );
};
